

/*
 * 		Plus-icon
 * 		---------
 */


.plus-icon-wrapper {

	display: table;
	border: 2px solid $primaryBlue;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	
	img {

		display: table-cell;
		width: 10px;
		height: 10px;
		padding: 8px;
		border: 0;
	}
}


.pdf-icon-wrapper {

	display: table;
	width: 26px;
	height: 26px;

	img {

		display: table-cell;
		height: 26px;
	}
}




