/*
 * 		Common Styles
 * 		-------------
 */

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $primaryFont;
  background-color: $bodyColor;
  font-size: 16px;
  line-height: 1em;
}

body {
  margin-bottom: 50px;
  overflow-x: hidden;

  @media (max-width: 860px) {
    margin-bottom: 39px;
  }
}

div,
p,
header,
nav,
aside,
section,
article {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

textarea,
input,
select {

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {

    outline: none;
  }
}

.clearfix {

  clear: both;
}

.alert {

  padding: 20px;
  border-radius: 2px;
  color: white;

  &.success {

    background: #76CE88;
  }
}

.thor_bar {
  padding: 8px 40px 8px 20px;
  background-color: #000000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 2;

  @media (max-width: 600px) {
    padding: 8px 20px;
  }

  .thor_bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .logo_thor {
      img {
        height: 31px;

        @media (max-width: 600px) {
          height: 20px;
        }
      }
    }
  }

  .thor_bar_logos {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none;
    }

    & > a {
      position: relative;
      padding-right: 15px;
      margin-right: 15px;

      @media (max-width: 600px) {
        padding-right: 10px;
        margin-right: 10px;
      }

      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        width: 1px;
        height: 16px;
        background-color: #86764E;
        transform: translateY(-50%);
      }

      &:last-child {
        padding-right: 0;
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &:nth-child(1) img {
        height: 24px;
      }

      &:nth-child(2) img {
        height: 24px;
      }

      &:nth-child(3) img {
        height: 31px;
      }

      &:nth-child(4) img {
        height: 18px;
      }

      &:nth-child(5) img {
        height: 18px;
      }

      @media (max-width: 600px) {
        &:nth-child(1) img {
          height: 16px;
        }

        &:nth-child(2) img {
          height: 16px;
        }

        &:nth-child(3) img {
          height: 23px;
        }

        &:nth-child(4) img {
          height: 10px;
        }

        &:nth-child(5) img {
          height: 10px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    &.is-opened {
      .thor_bar_container .logo_thor {
        margin-bottom: 10px;
      }

      .thor_bar_logos {
        display: flex;
      }
    }
  }

  .hover-effect {
    .is-hidden {
      display: none;
    }

    &:hover {
      img {
        display: none;

        &.is-hidden {
          display: inline-block;
        }
      }
    }
  }
}
