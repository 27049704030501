.momenti-hero{

  position: relative;
  background: url('../images/collection-header.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 20px;

  @media screen and (min-width: $mediumBreakpoint){

    padding: 50px 50px 0;
  }

  &:before{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(40,45,52,0.5);
    width: 100%;
    height: 100%;
  }

  .hero-content{

    position: relative;
    width: 100%;
    background-size: cover;
    text-align: center;

    @media screen and (min-width: $mediumBreakpoint){

      padding: 140px 50px;
    }

    .title-underlined{

      &:after{

        display: none;
      }
    }

    .button {

      margin: 0;

      @media screen and (min-width: $mediumBreakpoint){

        margin: inherit;
      }
    }
  }
}

.momenti-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  justify-content: center;

  .text-logo {

    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
  }

  .flex-text{

    align-self: center;
  }

  .flex-img{

    align-self: stretch;
  }

  .two-col{
    width: 100%;

    @media screen and (min-width:$mediumBreakpoint){

      width: 50%;
    }

    .full-col{
      width: 100%;

    }

    .half-col{
      width: 100%;
      float:left;

      @media screen and (min-width: $smallBreakpoint){

        width: 50%;
        height: 100%;
      }
    }

    img{

      width: 100%;
      vertical-align:middle;

      @media screen and (min-width: $smallBreakpoint){

        height: 100%;
      }
    }

    .text-block{

      padding: 45px;
      line-height: 25px;
      height: 100%;

    }
  }
}