
/*
 * 		List
 * 		----
 */


.list {
	color: $whiteFontColor;

	.title {

		margin: 0;
		padding:0 0 20px;
		text-transform: uppercase;
		font-size: 1.25em;
		font-weight: 300;

	}

	ul,
	ol {

		margin: 0;
		padding: 0;
		list-style-type: none;

		.nav-item {

			text-transform: uppercase;
			padding: 5px 0;
			font-size: 1.25em;
		}

		.list-item {

			margin: 0;
			padding: 5px 0 5px;
			font-size: 18px;
			font-weight: 300;

			&.red {

				color: $primaryRed;
			}

			ul {

				padding: 10px 0 0 20px;
			}

			a {

				color: $whiteFontColor;
				text-decoration: none;

				&:hover {

					color: $secondaryFontColor;
				}
			}
		}
	}

	ol {

		.list-item {

			margin-bottom: 8px;
		}
	}
}