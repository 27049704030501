.mikaelBanner {
    -js-display: flex;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $smallBreakpoint) {
        flex-direction: row;
        align-items: stretch;
    }

    .image {
        height: 250px;
        background: url('/assets/images/mikaelBanner.png') no-repeat left 15% center / cover;

        @media screen and (min-width: $smallBreakpoint) {
            width: 40%;
            height: auto;
        }

        @media screen and (min-width: $mediumBreakpoint) {
            width: 30%;
        }
    }

    .infos {
        padding: 20px;
        background: $primaryDark;

        @media screen and (min-width: $smallBreakpoint) {
            width: 60%;
            padding: 35px;
        }

        @media screen and (min-width: $mediumBreakpoint) {
            width: 70%;
            padding: 40px 70px;
        }

        @media screen and (min-width: $largeBreakpoint) {
            padding: 70px 100px;
        }

        .title-underlined {

            @media screen and (min-width: $largeBreakpoint) {
                font-size: 42px;
            }
        }

        .reading-text {

            @media screen and (min-width: $largeBreakpoint) {
                font-size: 20px;
            }
        }
    }
}