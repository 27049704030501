
.sale-points {

    border-bottom: 2px solid $primaryLight;
    height: 700px;
    position: relative;

    @media screen and (max-width: $largeBreakpoint) {

        height: 647px;
    }

    @media screen and (max-width: $mediumBreakpoint) {

        height: auto;
    }

    .sale-points-map {

        width: 50%;
        height: 100%;
        float: left;

        @media screen and (max-width: $largeBreakpoint) {

            width: 33.33334%;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            width: 100%;
            height: 400px;
        }
    }

    .sale-points-infos {

        width: 50%;
        height: 100%;
        overflow-y: scroll;
        float: left;
        background-color: $primaryFontColor;
        padding: 40px;

        @media screen and (max-width: $largeBreakpoint) {

            width: 66.66667%;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            display: block;
            width: 100%;
            padding: 20px;
        }

        .reading-info {
            position: relative;
            width: 100%;
            padding: 20px 20px 20px 90px;
            margin-bottom: 10px;
            background: rgba(white, .8);

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                bottom: 0;
                width: 70px;
                background: #2A9FD4 url('/assets/images/icon-info.svg') no-repeat center center / 30px;
            }
        }

        .label {

            margin: 0;
            color: $primaryLight;
        }

        .sale-points-input {

            width: calc(50% - 5px);
            float: left;
            position: relative;
            margin-bottom: 10px;

            @media screen and (max-width: $smallBreakpoint) {

                width: 100%;
            }

            &:before {

                position: absolute;
                left: 20px;
                top: 14px;
                font: normal normal normal 20px/1 FontAwesome;
                color: $primaryLight;
            }

            input {

                width: 100%;
                padding: 15px 15px 15px 60px;
                background: #393e44;
                color: $primaryLight;
                border: none;
                font-size: 16px;
                box-sizing: border-box;
            }

            &.keywords {

                margin-right: 10px;

                @media screen and (max-width: $smallBreakpoint) {

                    margin-right: 0;
                }

                &:before {

                    content: "\f002";
                }
            }

            &.zipcode:before {

                content: "\f041";
            }
        }

        .sale-points-results {

            margin-bottom: 30px;

            @media screen and (max-width: $mediumBreakpoint) {

                margin-bottom: 10px;
            }

            .sale-point {

                float: left;
                background-color: rgba(255,255,255,.8);
                width: calc(50% - 5px);
                padding: 30px;
                margin-bottom: 10px;
                transition: background-color ease-out .2s;

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 20px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    width: 100%;
                }

                &:nth-child(odd) {

                    margin-right: 10px;

                    @media screen and (max-width: $smallBreakpoint) {

                        margin-right: 0;
                    }
                }

                .title {

                    height: 70px;

                    @media screen and (max-width: $smallBreakpoint) {

                        height: auto;
                    }

                    .title-underlined div {

                        max-height: 40px;
                        overflow: hidden;

                        @media screen and (max-width: $smallBreakpoint) {

                            max-height: inherit;
                            overflow: inherit;
                        }
                    }
                }

                .title-underlined, .reading-text {

                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .reading-text {

                    height: 95px;
                    overflow: hidden;

                    @media screen and (max-width: $smallBreakpoint) {

                        height: auto;
                        overflow: inherit;
                    }
                }

                .sale-point-distance {

                    text-align: right;
                    font-size: 24px;
                }

                &.active {

                    background-color: $primaryLight;
                }
            }
        }

        .centered {

            background: url('../images/loader.svg') no-repeat center center;
            background-size: 60px;
            clear: both;

            .button {

                margin: 0;
                background: $primaryFontColor;
                transition: opacity ease-out .2s;
            }
        }

        &.loading .centered .button {

            opacity: 0;
        }

        .no-results {

            display: none;
            text-align: center;
            margin-top: 20px;
        }
    }
}


#cd-zoom {

    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

#cd-zoom-in, #cd-zoom-out {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-left: 10px;
    background-color: rgba(210, 38, 66, 0.9);
    background-repeat: no-repeat;
    background-size: 32px 64px;
    background-image: url('../images/cd-icon-controller.svg');

    .no-touch &:hover {
        background-color: rgba(210, 38, 66, 1);
    }
}

#cd-zoom-in {
    background-position: 50% 0;
    margin-bottom: 1px;
    margin-top: 10px;
}

#cd-zoom-out {
    background-position: 50% -32px;
}