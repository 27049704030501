

/*
 * 		Product Block row
 * 		-------------
 */


.product-block-row {

	clear: both;

	.product-block {

		float: left;
		position: relative;
		width: 25%;
		background: $primaryLight;

		@media screen and (max-width: $largeBreakpoint) {

			width: 33.33334%;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			width: 50%;
		}

		.product-image-wrapper {

			position: relative;
			width: 100%;
			padding-bottom: 102.75%;
			float: left;
			height: 0;

		    border-right: 1px solid $fifthLight;
		    border-bottom: 1px solid $fifthLight;

			background-repeat: no-repeat;
			background-position: center center;
			background-size: 75%;
		
			.product-image {

				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
			}

			.tags {

				position: absolute;
				top: 10px;
				right: 10px;

				.new {

					padding: 5px 10px;
					border-radius: 3px;
					background: $primaryBlue;
					font-size: 12px;
					color: $primaryLight;
				}

				.discontinued {

					padding: 5px 10px;
					border-radius: 3px;
					background: $fourthdDark;
					font-size: 12px;
					margin-left: 10px;
					color: $primaryLight;
				}
			}
		}
		
		
		.product-name {

			padding: 20px 50px 20px 20px;
		    border-right: 1px solid $fifthLight;
		    border-bottom: 1px solid $fifthLight;
			float: left;
			width: 100%;
			font-size: 16px;
			line-height: 16px;
			position: relative;
			color: $primaryFontColor;

			@media screen and (max-width: $smallBreakpoint) {

				font-size: 14px;
			}

			.product-inside-name {

				overflow: hidden;
				line-height: 17px;
				height: 17px;
			}

			.plus-icon-wrapper, .pdf-icon-wrapper {

				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 25px;

				img {

					border: 0;
				}
			}
		}

		&.show-all-cta {

			text-align:center;

			.product-block {

				width: 50%;

				.product-image-wrapper {

					padding-bottom: calc(102.75% + 28.5px);
				}

			}

			.product-cta-wrapper {

				display: block;
				position: relative;
				
				.content {

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					padding: 0;

					.plus-icon-wrapper {

						margin: 0 auto;
					}

					.reading-text {

						margin: 10px 0 0;
						line-height: 16px;
						color: $primaryFontColor;
					}
				}
			}
		}	

		&:hover {

			.product-name {

				background: $fourthLight;
			}
		}

	}

}




/*
 * 		Product Image Text
 * 		-------------
 */


.image-text-block {

	display: table;
	width: 100%;

	.image-block {

		display: table-cell;
		width: 50%;

		@media screen and (max-width: $mediumBreakpoint) {

			display: block;
			width: 100%;
			height: 50%;

			@-moz-document url-prefix() {

				min-height: 300px;
			}
		}

		img {

			display: block;
			width: 100%;
		}
	}

	.text-block-wrapper {

		position: relative;
		display: table-cell;
		width: 50%;
		margin: 0;
		background: $primaryDark;

		@media screen and (max-width: $mediumBreakpoint) {

			display: block;
			width: 100%;
			height: 50%;

			@-moz-document url-prefix() {

				min-height: 300px;
			}
		}

		.text-block {

			position: absolute;
			width: 70%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@media screen and (max-width: $smallBreakpoint) {


				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				padding: 0 20px;
			}

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

				position: relative;
				width: 100%;
				padding: 70px;
				transform: none;
				top: 0;
				left: 0;
			}
		}

		.button {
			margin: 0;
		}
	}
}


/*
 * 		Block left-navigation
 * 		-------------
 */


.left-nav-block {

	display: table;
	width: 100%;

	.aside-nav {
		
		display: table-cell;
		height: 1150px;
		width: 22%;
		background: $primaryDark;
		vertical-align: top;
		padding: 40px 0 0 0;

		.list {

			.title {

				padding: 5px 0 15px 50px;

				&:hover, &.active {
				
					background-color: $secondaryDark;
				}
			}

			ul {

				li {

					padding: 5px 0 5px 50px;
					
					&:hover, &.active {

						background-color: $secondaryDark;
					}

					ul {


					}
				}
			}
		}
	}

	.content-section {
		
		display: table-cell;
		width: 78%;

		.title-area {
			
			background-color: $bodyColor;
			padding: 35px 40px;

			.title-2 {

				display: inline-block;
				line-height: 1em;
				margin: 0 20px 0 0;
			}

			.active-filter {

				vertical-align: super;
			}
		}

		.content {

			background-color: $bodyColor;

		}

	}
}





.legal-text {

	padding: 50px;
	max-width: 1050px;
	margin: 0 auto;

	@media screen and (max-width: $mediumBreakpoint) {

		padding: 20px;
	}

	@media screen and (max-width: $smallBreakpoint) {

		padding: 0;
	}

	.legal-text-content {

		background: white;
		padding: 50px;
		border: 1px solid $fifthLight;

		@media screen and (max-width: $mediumBreakpoint) {

			padding: 20px;
		}

		@media screen and (max-width: $smallBreakpoint) {

			border: none;
		}

		.title-3 {

			margin: 10px 0 20px;
		}

		.reading-text:last-child {

			margin-bottom: 0;
		}

		.support-guides {

			padding: 0;

			@media screen and (max-width: $mediumBreakpoint) {

				padding: 0;
			}
		}

		.academy {

			width: 100%;
			margin-bottom: 20px;

			&.first {

				margin-top: 20px;
			}
		}
	}
}