.faucet-hero {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 20px;
  transition: background ease-out .2s;

  @media screen and (min-width: $mediumBreakpoint){

    padding: 50px 50px 0;
  }

  &.type_S01 {
    background-image: url('../images/MMSQ01.jpg');
  }

  &.type_08 {
    background-image: url('../images/MMRD08.jpg');
  }

  &.type_12 {
    background-image: url('../images/MMSQ12.jpg');
  }

  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(40, 45, 52, 0.5);
    width: 100%;
    height: 100%;
  }

  .hero-content {
    position: relative;
    width: 100%;
    background-size: cover;
    text-align: center;

    @media screen and (min-width: $mediumBreakpoint){

      padding: 140px 50px;
    }

    .title-2 {

      color: white;
      margin: 50px 0 30px;
      text-transform: initial;
    }
  }

  .button.checked{

    background: rgba(0, 0, 0, 0.5);
  }
}

.faucet-content {
  padding-bottom: 23px;
  background: white;
  text-align: center;

  .faucet-form {
    padding: 0 30px;

    @media screen and (min-width: $mediumBreakpoint){

      padding: 0;
    }

    .error-message{

      margin: 15px 0 0;
      font-size: 18px;

      .fa{

        padding-right: 10px;
      }
    }

    .flex-container {

      display: flex;
      flex-wrap: wrap;

      @media screen and (min-width: $mediumBreakpoint){

        flex-wrap: nowrap;
      }

      .one-third {
        padding: 23px 0 90px;
        border-bottom: 1px solid $bodyColor;
        width: 100%;

        &:last-of-type{

          border: none;
        }

        @media screen and (min-width: $mediumBreakpoint){

          padding: 23px 30px 90px;
          border-right: 1px solid $bodyColor;
          border-bottom: none;
        }

        .section-title {

          font-size: 20px;
          text-transform: uppercase;
        }

        .input-holder{

          padding: 20px;
          border: 2px solid transparent;
          cursor: pointer;

          &.checked{

            border-color: #2A9FD8;
          }
        }

        input {

          cursor: pointer;
          appearance: none;
          border: none;
          display: none;
        }

        label {
          display: block;
          font-size: 20px;
          cursor: pointer;
        }

        /*
          CHOIX DE BEC
        */

        .spout-holder {
          display: inline-block;
          margin: 60px 5% 0;

          .spout-type {
            height: 210px;
            margin-bottom: 27px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center bottom;
            z-index: 1;
            background-size: contain;

            &.round-spout {
              width: 131px;
              background-image: url('../images/bec1.svg');

            }

            &.square-spout {
              width: 141px;
              background-image: url('../images/bec2.svg');

            }
          }

        }

        /*
          CHOIX DE POIGNÉE
        */

        .input-row {

          .handle-holder {
            display: inline-block;
            margin: 60px 6% 0;

            label.bigger-font{

              font-size: 30px;
            }

            .handle-type {
              margin-bottom: 27px;
              background-color: transparent;
              background-repeat: no-repeat;
              background-position: center bottom;
              z-index: 1;
              background-size: contain;

              &.handle1 {
                width: 98px;
                height: 101px;
                background-image: url('../images/poignee1.png');

              }

              &.handle2 {
                width: 106px;
                height: 107px;
                background-image: url('../images/poignee2.png');

              }

              &.handle3 {
                width: 36px;
                height: 108px;
                background-image: url('../images/poignee3.png');

              }

              &.handle4 {
                width: 37px;
                height: 105px;
                background-image: url('../images/poignee4.png');

              }
            }

            &:last-of-type{
              margin-left: 17%;
            }
          }

        }

        /*
          CHOIX DE POLI
        */


        .flex-container{

          js-display: flex;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;

          .finish-holder {
            display: inline-block;
            margin: 60px 0 0;

            .finish-type {
              margin: 0 auto 27px;
              width: 57px;
              height: 57px;
              background-size: 57px 57px;
              background-color: transparent;
              background-repeat: no-repeat;
              background-position: center bottom;
              z-index: 1;

              &.finish1 {
                background-image: url('../images/momenti-BN.png');

              }

              &.finish2 {
                background-image: url('../images/momenti-BNBK.png');

              }

              &.finish3 {
                background-image: url('../images/momenti-PN.png');

              }

              &.finish4 {
                background-image: url('../images/momenti-PNBK.png');

              }

              &.finish5 {
                background-image: url('../images/momenti-C.png');

              }

              &.finish6 {
                background-image: url('../images/momenti-CBK.png');

              }

              &.finish7 {
                background-image: url('../images/momenti-BK.png');

              }
            }

            label{

              max-width: 90px;
              line-height: 23px;
            }
          }
        }
      }
    }

    .form-submit {

      background: transparent;
      margin: 0 auto;
    }
  }
}

.faucetVisualisation {

  position: relative;
  overflow: hidden;
  max-width: 100vw;

  .faucetVisualisationSlider {

    overflow: hidden;
    width: 400vw;
    transition: transform ease-out .2s;

    .faucetVisualisationBackground {

      float: left;
      width: 100vw;

      img {

        width: 100vw;
      }

      .image_test {

        width: 100%;
        padding-top: 42.649%;

        &.beige {

          background-color: #C0BAA3;
        }

        &.gray {

          background-color: #6F6D6C;
        }

        &.blue {

          background-color: #80ADBB;
        }

        &.dark {

          background-color: #21263A;
        }
      }
    }
  }

  .faucentVisualisationImage {

    position: absolute;
    transition: all ease-out .2s;

    left: 37%;
    width: 42.5%;
    top: inherit;
    bottom: 31.6%;

    -ms-interpolation-mode: bicubic; 

    &.bath {

      left: 28%;
      width: 38.5%;
      bottom: 19.6%;
      transform: rotate(-2deg);

      &.index-1 {

        left: 29.3%;
        width: 36%;
        bottom: 21.5%;
        transform: rotate(0deg);
      }

      &.index-2 {

        left: 29.2%;
        width: 36.3%;
        bottom: 21.5%;
        transform: rotate(0deg);
      }

      &.index-3 {

        left: 29.2%;
        width: 36.3%;
        bottom: 21.4%;
        transform: rotate(0deg);
      }
    }

    &.monotrou {

      left: 35%;
      bottom: 30.5%;
    }
  }
}

.resultsSwitches {

  overflow: hidden;
  background: #ffffff;

  .section-left {

    float: left;

    a {

      display: block;
      padding: 20px 30px 20px 60px;
      color: $primaryDark;
      text-transform: uppercase;
      line-height: 50px;
      text-decoration: none;
      background: url('../images/momenti-chevron.svg') no-repeat left 30px center / auto 20px;
    }
  }

  .section-right {

    float: right;
    overflow: hidden;
    padding: 20px 30px;

    .resultSwitch {

      float: left;
      margin-left: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      transition: opacity ease-out .2s;
      cursor: pointer;
      background-position: center center;
      background-size: cover;

      &.active, &:hover {

        opacity: .5;
      }

      &.one {

        background-image: url('../images/momenti-thumb-1.png');
      }

      &.two {

        background-image: url('../images/momenti-thumb-2.png');
      }

      &.three {

        background-image: url('../images/momenti-thumb-3.png');
      }

      &.four {

        background-image: url('../images/momenti-thumb-4.png');
      }

      &.five {

        background-image: url('../images/momenti-thumb-5.png');
      }

      &.six {

        background-image: url('../images/momenti-thumb-6.png');
      }

      &.seven {

        background-image: url('../images/momenti-thumb-7.png');
      }

      &.height {

        background-image: url('../images/momenti-thumb-8.png');
      }

      &.nine {

        background-image: url('../images/momenti-thumb-9.png');
      }

      &.ten {

        background-image: url('../images/momenti-thumb-10.png');
      }

      &.eleven {

        background-image: url('../images/momenti-thumb-11.png');
      }

      &.twelve {

        background-image: url('../images/momenti-thumb-12.png');
      }
    }
  }
}

.resultLinks {

  overflow: hidden;

  .resultLink {

    box-sizing: border-box;
    float: left;
    width: 100%;
    display: block;
    background-color: $bodyColor;
    padding: 20px 30px;
    text-transform: uppercase;
    text-decoration: none;
    color: $primaryDark;
    background-repeat: no-repeat;
    background-position: right 30px center;
    background-size: auto 30px;
    line-height: 50px;

    @media screen and (min-width: $mediumBreakpoint){

      width: 33.33334%;
    }

    img {
      height: 30px;
      vertical-align: text-bottom;
    }

    &.save {

      background-image: url('../images/momenti-save.svg');
    }

    &.share {
      background-color: #b6babd;
      background-image: url('../images/momenti-share.svg');
    }

    &.view {

      background-image: url('../images/momenti-view.svg');
    }
  }
}

#savingResult {

  display: none;
}

.make-faucet-error {

  color: $primaryRed;
  margin: 30px 0;
  display: none;
}