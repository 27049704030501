
/*
 * 		Basic button
 * 		------------
 */


.button {

	display: inline-block;
	padding: 20px 40px;
	margin: 10px 5px;
	border: 2px solid $secondaryLight;
	//min-width: 125px;
	font-size: 16px;
	font-family: $primaryFont;
	text-transform: uppercase;
	text-align: center;
	color: $primaryLight;
	transition: color .2s ease, border-color .2s ease, background-color .2s ease;
	text-decoration: none;
	cursor: pointer;

	//&:hover {
    //
	//	border-color: $primaryBlue;
	//}

	&:disabled {
		cursor: default;
		opacity: .5;
	}

	@media screen and (max-width: $smallBreakpoint) {

		padding: 15px 18px;
	}

	@media screen and (max-width: $tinyBreakpoint) {

		width: 100%;
		box-sizing: border-box;
	}

	&:hover, &.active {

		background: rgba(0,0,0,.5);
	}

	&.blue {

		border-color: $primaryBlue;
		color: $primaryLight;
	}

	&.light {

		border: 2px solid $secondaryLight;
		color: $primaryLight;
	}

	&.dark {

		border-color: $primaryDark;
		color: $primaryDark;

		&:hover {

			border-color: $primaryBlue;
			background: none;
		}
	}

	&.fixed {

		min-width: 235px;

		@media screen and (max-width: 1200px) {

			min-width: 165px;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			min-width: inherit;
		}
	}

	&.small {

		padding: 15px 30px;
	}
}

