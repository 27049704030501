
.inspiration-wrapper {

    margin: 50px;
    text-align: center;

    @media screen and (max-width: $mediumBreakpoint) {

        margin: 20px;
    }

    .inspiration-item {

        float: left;
        width: 25%;
        padding: 5px;
        box-sizing: border-box;

        @media screen and (max-width: $largeBreakpoint) {

            width: 33.33334%;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            width: 50%;
        }


        &:hover .inspiration-link {

            background-color: $fourthLight;
        }

        .inspiration-image {

            float: left;
            width: 100%;
            background-color: $primaryLight;
        }

        .inspiration-link {

            position: relative;
            float: left;
            width: 100%;
            box-sizing: border-box;
            padding: 25px 80px 25px 30px;
            background-color: $primaryLight;
            color: $primaryFontColor;
            text-decoration: none;
            text-align: left;

            @media screen and (max-width: $smallBreakpoint) {

                padding: 15px;
            }

            &:after {

                content: '+';
                position: absolute;
                width: 26px;
                height: 26px;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                border: 2px solid $primaryBlue;
                border-radius: 30px;
                color: $primaryBlue;
                line-height: 26px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;

                @media screen and (max-width: $smallBreakpoint) {

                    display: none;
                }
            }
        }
    }
}

.inspiration-button {

    margin: 0 auto 50px;
    text-align: center;

    @media screen and (max-width: $mediumBreakpoint) {

        margin-bottom: 20px;
    }

    @media screen and (max-width: $smallBreakpoint) {

        margin: 0 20px 20px;
    }

    .button {

        margin: 0;
    }
}