.collection-2017{

  .footer-wrapper{

    text-align: center;

    p{

      color: white;
      padding: 40px 0;
    }
  }
}