
/*
 * 		Input
 * 		-----
 */

::-webkit-input-placeholder {
	color: $fourthdDark;
}

:-moz-placeholder { /* Firefox 18- */
	color: $fourthdDark;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: $fourthdDark;
}

:-ms-input-placeholder {
	color: $fourthdDark;
}


.input, .textarea {

	padding: 10px;
	background-color: $fourthLight;
	border: 1px solid $thirdLight;
	border-radius: 1px;
	color: $thirdDark;
	font-size: 16px;

	&.error {

		background-color: rgba(255, 0 ,0, 0.1);
	}
}




/*
 * 		Select Group
 * 		-----
 */


.select-group {

	position: relative;
    width: 40%;
    display: block;
    cursor: pointer;

	 @media screen and (min-width: $smallBreakpoint) {

    	width: 25%;
    }

     @media screen and (min-width: $mediumBreakpoint) {

    	width: 20%;
    }

    @media screen and (min-width: $largeBreakpoint) {

    	width: 15%;
    }

	&.small {

		float: left;
		width: calc(50% - 10px);
		margin: 0 0 10px;

		&:nth-child(even) {

			margin-right: 20px;
		}

		@media screen and (max-width: $smallBreakpoint) {

			width: 100%;
		}

		.label {
			display: block;
			margin-bottom: 10px;
		}
	}


    .select {

		width: 100%;
		padding: 10px 12px;
	    border: none;
	    border-radius: 0;
	    background-color: $primaryLight;
	    background-image: none;
	    box-shadow: none;
		font-size: 16px;
		cursor: pointer;
		font-family: $primaryFont;
		text-transform: uppercase;
		color: $primaryFontColor;
	}

	&:after {

		content: "";
	    position: absolute;
	    bottom: 12px;
	    right: 15px;
	    display: inline-block;
	    width: 12px;
	    height: 12px;
	    background-image: url('../images/down-arrow.svg');
	    cursor: pointer;
	    pointer-events: none;
	    background-size: 12px 12px;
	    background-repeat: no-repeat;
	}


}




/*
 * 		Input Group Button
 * 		------------------
 */


.input-group-button {

	width: auto;
	max-height: 60px;
	vertical-align: top;

	.input {

		float: left;
		margin: 0;
		vertical-align: top;
		padding: 15px;
		width: calc(100% - 88px);
		box-sizing: border-box;

		@-moz-document url-prefix() {

			padding: 16px;
		}
	}

	.submit {

		float: left;
		position: relative;
		margin: 0;
		padding: 16px 20px;
	    background-color: $primaryBlue;
    	border: none;
		cursor: pointer;

    	.arrow {

    		width: 16px;
    		height: 16px;
    	}
	}

	&.error {

		.input {

			background-color: rgba(255, 0 ,0, 0.1);
		}
	}
}




/*
 * 		Form Group
 * 		------------------
 */


.form-group {

	margin-bottom: 15px;
}

.form-input {

	width: 100%;
	border: none;
	border-radius: 3px;
	font-size: 14px;
	padding: 5px;
	box-sizing: border-box;
}

textarea {

	height: 150px;
}

input[type=file] {

	display: none;
}

.form-file-label {

	width: 100%;
	text-align: center;
	border-radius: 3px;
	font-size: 16px;
	padding: 7px;
	background: $primaryLight;
	color: $primaryFontColor;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	i.fa {

		margin-right: 5px;
	}
}

.error-message {

	color: $primaryRed;
	margin: 5px 0;
}

.label .required {

	color: $primaryRed;
	margin-left: 5px;
}
