
.contact-table {

    display: table;
    width: 100%;
    border-bottom: 2px solid $primaryLight;

    @media screen and (max-width: $largeBreakpoint) {

        display: block;
    }

    .contact-introduction {
        margin: 20px 0 50px;
        font-size: 16px;
        line-height: 20px;
    }

    .contact-form {

        display: table-cell;
        width: 50%;
        padding: 50px 75px;
        vertical-align: top;

        @media screen and (max-width: $largeBreakpoint) {

            display: block;
            width: 100%;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;
        }

        .title-underlined {

            margin-top: 20px;
            font-size: 24px;
            line-height: 24px;
            font-weight: 400;

            @media screen and (max-width: $mediumBreakpoint) {

                margin-top: 0;
            }
        }

        input[type=file] {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }

        .input-group {

            &.small {

                float: left;
                width: calc(50% - 10px);

                &:nth-child(odd) {

                    margin-right: 20px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    width: 100%;
                }
            }

            .input, .textarea {

                width: 100%;
                box-sizing: border-box;
                margin: 10px 0;
            }
        }

        .button {

            background-color: #E4E9EC;
            transition: opacity ease-out .2s;
        }
    }

    .contact-places {

        display: table-cell;
        width: 50%;
        vertical-align: top;
        background-color: $primaryFontColor;
        padding: 50px;

        @media screen and (max-width: $largeBreakpoint) {

            display: block;
            width: 100%;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;
        }

        .contact-place {

            margin-bottom: 5px;

            @media screen and (max-width: $smallBreakpoint) {

                margin-bottom: 25px;
            }

            &:last-child {

                margin-bottom: 0;
            }

            .contact-place-title {

                padding: 30px;
                background-color: #393e44;
                color: $primaryLight;
                font-size: 24px;
                line-height: 24px;
                text-transform: uppercase;
                cursor: pointer;
                position: relative;
                font-weight: 300;

                @media screen and (max-width: $smallBreakpoint) {

                    padding: 20px 55px 20px 20px;
                    font-size: 18px;
                }

                .tcon {

                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    &:before, &:after {

                        background: $primaryBlue;
                        height: 7%;
                    }

                    @media screen and (max-width: $smallBreakpoint) {

                        right: 0px;
                    }
                }
            }

            .contact-place-dropdown {

                .contact-place-table {

                    display: table;
                    width: 100%;
                    max-height: 383px;

                    @media screen and (max-width: $smallBreakpoint) {

                        display: block;
                    }

                    .contact-place-infos {

                        display: table-cell;
                        width: 40%;
                        vertical-align: top;
                        background-color: $bodyColor;
                        padding: 30px;

                        @media screen and (max-width: $smallBreakpoint) {

                            display: block;
                            width: 100%;
                            padding: 20px;
                        }

                        .reading-text {

                            font-weight: 500;

                            span {

                                color: $primaryRed;
                            }

                            &.phone {

                                margin-bottom: 30px;
                            }

                            &.fax {

                                margin-bottom: 100px;
                            }

                            &.address {

                                margin-bottom: 0;
                            }

                            @media screen and (max-width: $smallBreakpoint) {

                                &.phone, &.fax {

                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }

                    .contact-place-map {

                        display: table-cell;
                        width: 60%;
                        vertical-align: top;

                        @media screen and (max-width: $smallBreakpoint) {

                            display: block;
                            width: 100%;
                            height: 200px;
                        }
                    }
                }
            }
        }
    }
}
