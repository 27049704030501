
.support-table {

    display: table;
    border-bottom: 2px solid $primaryLight;
    width: 100%;

    .support-menu-mobile {

        display: none;

        @media screen and (max-width: $smallBreakpoint) {

            display: block;
            color: $primaryLight;
            text-decoration: none;
            font-size: 14px;
            text-transform: uppercase;
            padding: 20px;
            background: #282d34;
        }

        i.fa {

            float: right;
            font-size: 22px;
            line-height: 15px;
        }
    }

    .support-menu {

        display: table-cell;
        width: 325px;
        background: $primaryFontColor;
        vertical-align: top;

        @media screen and (max-width: $largeBreakpoint) {

            width: 25%;
        }

        @media screen and (max-width: $smallBreakpoint) {

            display: none;
            width: 100%;
        }

        .support-menu-link {

            display: block;
            color: $primaryLight;
            text-decoration: none;
            font-size: 14px;
            text-transform: uppercase;
            padding: 20px 50px;

            @media screen and (max-width: $mediumBreakpoint) {

                padding: 20px;
            }

            @media screen and (max-width: $smallBreakpoint) {

                background: #282d34;
                box-sizing: border-box;

                &.active {

                    display: none;
                }
            }

            &:hover, &.active {

                background-color: #383d43;
            }
        }
    }

    .support-page {

        display: table-cell;
        width: calc(100% - 325px);
        background: $primaryLight;

        @media screen and (max-width: $largeBreakpoint) {

            width: 75%;
        }

        @media screen and (max-width: $smallBreakpoint) {

            display: block;
            width: 100%;
        }

        .support-title {

            font-size: 28px;
            font-weight: 300;
            padding: 40px;
            background: $bodyColor;
            text-transform: uppercase;
            border-bottom: 1px solid $fifthLight;

            @media screen and (max-width: $mediumBreakpoint) {

                padding: 30px 20px;
            }

            @media screen and (max-width: $smallBreakpoint) {

                display: none;
            }

            .title {

                float: left;
            }

            .support-search {

                display: block;
            }

            &.search {

                &.fixed {

                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 5;
                }

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 40px 20px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    display: block;
                    padding: 30px 10px;
                }

                .support-search {

                    width: 100%;
                    margin: -15px 0 -20px 0;

                    &:after {

                        left: inherit;
                        right: 25px;
                    }

                    @media screen and (max-width: $mediumBreakpoint) {

                        padding-top: 0;

                        &:after {

                            top: 25px;
                        }
                    }


                    @media screen and (max-width: $smallBreakpoint) {

                        border: none;
                        padding: 0;
                        margin: -17px 0 -20px 0;

                        &:after {

                            right: 15px;
                        }
                    }

                    .form-input {

                        padding: 15px 45px 15px 15px;
                        width: 100%;


                        @media screen and (max-width: $smallBreakpoint) {

                            padding: 15px 35px 15px 15px;
                        }
                    }
                }
            }
        }

        .support-content {

            padding: 0;
            min-height: 900px;

            @media screen and (max-width: $smallBreakpoint) {

                min-height: inherit;
            }

            &.for-text {

                max-width: 950px;
                padding: 70px 50px;
                margin: auto;
                min-height: inherit;

                @media screen and (max-width: $largeBreakpoint) {

                    padding: 50px;
                }

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 20px;
                }

                .title-3 {

                    margin-bottom: 20px;
                }
            }

            .title {

                font-size: 22px;
                text-transform: uppercase;
                margin: 0 0 20px;
                font-weight: 300;

                @media screen and (max-width: $smallBreakpoint) {

                    font-size: 18px;
                }
            }

            .support-list {

                list-style: none;
                padding-left: 20px;
                margin-bottom: 30px;
                line-height: 23px;

                @media screen and (max-width: $smallBreakpoint) {

                    padding-left: 0;
                    font-size: 14px;
                    line-height: 16px;
                }

                li {

                    position: relative;
                    padding-left: 17px;
                    margin-bottom: 10px;

                    &:before {
                        content: "";
                        width: 7px;
                        height: 7px;
                        background-color: $primaryBlue;
                        float: left;
                        margin: 5px 10px 0 0;
                        border-radius: 50%;
                        position: absolute;
                        left: 0px;
                    }
                }
            }

            .no_results {

                display: none;
            }
        }

        .partial-search {

            .publication-wrapper {

                background: $bodyColor;
                padding: 5px;
            }

            .support-content {

                display: none;

                &.products {

                    display: block;
                }
            }

            .no-results {

                display: none;
                padding: 50px;

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 20px;
                }
            }

            &.loading {

                .next-products .button {

                    opacity: 0;
                }
            }
        }
    }

    .support-search {

        display: none;
        float: left;
        position: relative;
        margin: -15px 0 -20px 20px;

        &:after {

            position: absolute;
            left: 220px;
            top: 25px;
            transform: translateY(-50%);
            content: "\f002";
            font: normal normal normal 16px/1 FontAwesome;
            color: $fifthLight;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            float: inherit;
            margin: 0;
            clear: both;
            padding: 20px 0 0 0;

            &:after {

                top: 45px;
                left: inherit;
                right: 25px;
            }
        }

        @media screen and (max-width: $smallBreakpoint) {

            display: block;
            background: $bodyColor;
            padding: 20px;
            border-bottom: 1px solid $fifthLight;

            &:after {

                right: 35px;
            }
        }

        input {

            padding: 15px 35px 15px 15px;
            border: 1px solid $fifthLight;
            width: 250px;

            @media screen and (max-width: $mediumBreakpoint) {

                width: 100%;
            }
        }

        .form-error {

            color: $primaryRed;
            font-size: 16px;
            margin-top: 10px;

            @media screen and (max-width: $mediumBreakpoint) {

                text-align: center;
            }

            @media screen and (max-width: $smallBreakpoint) {

                font-size: 14px;
            }
        }
    }
}

.support-guides {

    max-width: 1050px;
    margin: 0 auto;
    padding: 50px;

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 20px;
    }

    .support-guide {

        width: 100%;
        display: block;
        padding: 20px 70px 20px 20px;
        margin-bottom: 30px;
        box-sizing: border-box;
        font-size: 24px;
        text-decoration: none;
        text-transform: uppercase;
        line-height: 30px;
        border: 1px solid $fifthLight;
        background: url('../images/support-pdf.png') no-repeat center right 20px;
        color: $primaryFontColor;

        @media screen and (max-width: $mediumBreakpoint) {

            font-size: 18px;
            background-size: 25px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: $smallBreakpoint) {

            padding: 10px 30px 10px 10px;
            font-size: 16px;
            background-size: 20px;
            line-height: 20px;
            background-position: center right 10px;
        }
    }
}