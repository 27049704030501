/*
 * 		Settings
 * 		--------
 */


@import 'settings';




/*
 * 		Components
 * 		----------
 */


@import 'components/typography';
@import 'components/button';
@import 'components/form';
@import 'components/list';
@import 'components/icon';
@import 'components/animated_buttons';




/*
 * 		Modules
 * 		-------
 */


@import 'modules/header';
@import 'modules/footer';
@import 'modules/navigation';
@import 'modules/block';
@import 'modules/publication';
@import 'modules/videos';
@import 'modules/lity.min';
@import 'modules/lightbox';
@import 'modules/newsletter';
@import 'modules/contest';
@import 'modules/mikaelBanner';




/*
 * 		Templates
 * 		---------
 */


@import 'templates/common';
@import 'templates/home';
@import 'templates/menu';
@import 'templates/products-list';
@import 'templates/product';
@import 'templates/support';
@import 'templates/about';
@import 'templates/inspiration';
@import 'templates/carrieres';
@import 'templates/salespoints';
@import 'templates/contact';
@import 'templates/faq';




/*
 * 		Collection 2017
 * 		---------
 */


@import 'collection_2017/common';
@import 'collection_2017/momenti';
@import 'collection_2017/make-faucet';
@import 'collection_2017/footer';


