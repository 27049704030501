
.support-content {

    .main-video {

        display: none;
        padding: 50px;
        text-align: center;
        border-bottom: 1px solid $fifthLight;
        background: $fourthLight;
        -webkit-transition: height ease-out .2s;
        -moz-transition: height ease-out .2s;
        -ms-transition: height ease-out .2s;
        -o-transition: height ease-out .2s;
        transition: height ease-out .2s;

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 0;
            border: none;
            background: none;

        }

        &.visible {

            display: block;
        }
    }

    .videos-list {

        padding: 50px;

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;

        }

        .videos-item {

            position: relative;
            float: left;
            width: calc(50% - 15px);
            margin-bottom: 30px;
            transition: opacity ease-out .2s;

            @media screen and (max-width: $mediumBreakpoint) {

                width: calc(50% - 10px);
            }

            &:nth-child(odd) {

                margin-right: 15px;

                @media screen and (max-width: $mediumBreakpoint) {

                    margin-right: 10px;
                }
            }

            &:nth-child(even) {

                margin-left: 15px;

                @media screen and (max-width: $mediumBreakpoint) {

                    margin-left: 10px;
                }
            }

            &:hover {

                opacity: .85;
            }

            &.active {

                opacity: .5;
            }

            .name {

                margin-bottom: 15px;
                height: 16px;
                overflow: hidden;
            }

            .image {

                width: 100%;
                padding-top: 56.25%;
                background-position: center center;
                background-size: cover;
                position: relative;

                .play_icon {

                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: url('../images/video_play.png') no-repeat center center;
                    cursor: pointer;

                    @media screen and (max-width: $mediumBreakpoint) {

                        background-size: 50px;
                    }

                    @media screen and (max-width: $smallBreakpoint) {

                        background-size: 30px;
                    }
                }
            }

        }
    }

    #player {

        @media screen and (max-width: $mediumBreakpoint) {

            width: 100%;
            height: 324px;

        }

        @media screen and (max-width: $smallBreakpoint) {

            height: 180px;

        }
    }
}