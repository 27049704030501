
.products-listing, .products-listing-momenti {

  display: table;
  border-bottom: 2px solid $primaryLight;
  width: 100%;

  .products-menu {

    display: table-cell;
    width: 325px;
    vertical-align: top;
    background-color: #282d33;

    @media screen and (max-width: $largeBreakpoint) {

      width: 25%;
    }

    @media screen and (max-width: $mediumBreakpoint) {

      padding: 20px;
    }

    @media screen and (max-width: $smallBreakpoint) {

      position: relative;
      float: left;
      width: 100%;
      height: auto;
      min-height: inherit;
      padding-bottom: 0;
    }

    .scroll {

      @media screen and (min-width: 768px) {

        width: calc(20% - 20px);
        overflow-y: scroll;
        position: fixed;
        margin: 20px;
        padding: 5px 0;
        box-sizing: border-box;
        background-color: #282d33;
      }

      @media screen and (min-width: 1071px) {

        width: calc(20% - 50px);
        margin: 50px;
      }

      @media screen and (min-width: 1310px) {

        width: calc(325px - 50px);
      }
    }

    .title-top {

      color: $primaryLight;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 40px;
    }

    .group {

      margin-bottom: 40px;

      @media screen and (max-width: $smallBreakpoint) {

        margin-bottom: 20px;
      }

      &:last-child {

        margin-bottom: 0;
      }

      .title-3 {

        margin-bottom: 20px;

        @media screen and (max-width: $smallBreakpoint) {

          font-size: 16px;
        }

        .tcon {

          display: none;

          &:before, &:after {

            height: 10%;
          }

          @media screen and (max-width: $smallBreakpoint) {

            display: block;
            float: right;
            top: -7px;
          }
        }
      }

      ul {

        list-style: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: $smallBreakpoint) {

          display: none;
        }

        li {

          margin-bottom: 10px;
          padding-right: 20px;

          @media screen and (max-width: $smallBreakpoint) {

            font-size: 14px;
          }
        }

        &.show_row {

          display: none;
        }
      }

      a {

        margin-bottom: 10px;
        color: $menuColor;
        text-decoration: none;

        &.selected {

          color: $primaryBlue;
        }

        &.show_all {

          color: $primaryLight;

          @media screen and (max-width: $smallBreakpoint) {

            display: none;
          }

          .text-less {

            display: none;
          }

          &.less {

            .text-all {

              display: none;
            }

            .text-less {

              display: block;
            }
          }
        }
      }

      .menu-sub-menu {
        margin-top: 10px;
        padding-left: 20px;
      }
    }
  }

  .products-products {

    position: relative;
    display: table-cell;
    width: calc(100% - 325px);

    @media screen and (max-width: $largeBreakpoint) {

      width: 75%;
    }

    @media screen and (max-width: $smallBreakpoint) {

      float: left;
      width: 100%;

      .product-name {

        padding: 20px;
        text-align: center;

        .plus-icon-wrapper {

          display: none;
        }
      }
    }

    .categories {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 60px;
      background: $primaryLight;

      .categories-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .categories-item {
        position: relative;
        padding: 30px 30px 0;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $primaryFontColor;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -25px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $bodyColor;
          transition: .4s all;
        }

        &.is-active {
          color: $primaryRed;

          &::after {
            bottom: -26px;
            height: 3px;
            background: $primaryRed;
          }
        }
      }
    }

    .filters {

      padding: 40px;

      &.fixed {

        position: fixed;
        z-index: 2;
        width: calc(100% - 325px);
        background-color: $bodyColor;

        @media screen and (max-width: $largeBreakpoint) {
          width: 75%;
        }
      }

      &.absoluted {

        position: absolute;
        z-index: 2;
        width: 100%;
        background-color: $bodyColor;
      }

      @media screen and (max-width: $mediumBreakpoint) {

        padding: 20px;
      }

      .filters-content {
        display: flex;
        flex-wrap: wrap;
      }

      .title {
        margin: 0 30px 10px 0;
        font-size: 28px;
        line-height: 34px;
        font-weight: 300;
        text-transform: uppercase;

        @media screen and (max-width: $smallBreakpoint) {

          font-size: 22px;
        }
      }

      .subtitle {
        margin-top: 10px;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $primaryRed;
      }

      .tags {

        flex-grow: 1;
        margin-bottom: 20px;

        @media screen and (max-width: $mediumBreakpoint) {

          clear: both;
          margin-top: 10px;
        }

        .tag {

          float: left;
          margin: 0 10px 10px 0;
          padding: 10px 0 10px 15px;
          background: $primaryLight;
          border-radius: 3px;
          font-size: 14px;
          line-height: 14px;

          &:after {

            content: 'x';
            margin-left: 15px;
            padding: 8px 10px;
            background: $primaryFontColor;
            color: $primaryBlue;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            cursor: pointer;
          }
        }

        .new_search {

          display: none;
          float: right;
          margin: 0 10px 10px 0;
          padding: 10px 15px 10px 15px;
          background: $primaryDark;
          border-radius: 3px;
          font-size: 14px;
          line-height: 14px;
          color: $primaryLight;
          cursor: pointer;

          i {

            margin-right: 5px;
          }
        }

        &.full .new_search {

          display: block;
        }
      }

      .actions {
        .action-checkbox {
          position: relative;
          margin-right: 20px;
          padding: 8px 10px;
          border-radius: 3px;
          font-size: 14px;
          color: $whiteFontColor;
          background: #282d33;
          transition: .4s ease;
          cursor: pointer;

          &.is-active {
            background: $primaryBlue;
          }

          &:last-of-type {
            margin-right: 0;
          }

          input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            cursor: pointer;
          }
        }
      }

      .filters-text {
        width: 100%;
      }
    }
  }

  .no-results {

    display: none;
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.next-products {

  width: 100%;
  padding: 40px;
  text-align: center;
  background: url('../images/loader.svg') no-repeat center center;
  background-size: 70px;

  .button, .button:hover {

    background-color: $bodyColor;
    transition: opacity ease-out .2s;
  }

  &.light {

    .button, .button:hover {

      background: $primaryLight;
    }
  }

  @media screen and (max-width: $mediumBreakpoint) {

    padding: 20px;
  }

  @media screen and (max-width: $smallBreakpoint) {

    background-size: 60px;
  }
}

&.loading .next-products .button {

  opacity: 0;
}
