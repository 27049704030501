.contest_popup {
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: -1;
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    transition: all ease-out .3s;

    &.active {

        opacity: 1;
        z-index: 9999;
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .newsletter_layout {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(#000000, .5);
    }

    .newsletter_wrapper {

        position: absolute;
        width: 700px;
        max-width: calc(100vw - 40px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $primaryDark;
        padding: 50px;
        -webkit-box-shadow: 0 0 10px 0 rgba($primaryDark,0.5);
        -moz-box-shadow: 0 0 10px 0 rgba($primaryDark,0.5);
        box-shadow: 0 0 10px 0 rgba($primaryDark,0.5);
        color: $primaryLight;

        @media screen and (max-width: $smallBreakpoint) {

            padding: 20px;

            .title-underlined {

                font-size: 20px;
            }
        }

        .content {

            margin-bottom: 20px;
            overflow: hidden;

            .reading-text {

                margin-bottom: 40px;

                @media screen and (max-width: $smallBreakpoint) {

                    margin-bottom: 20px;
                }
            }

            input.input {

                width: 100%;
                margin-bottom: 20px;
             }

            button.submit {

                color: $primaryLight;
                background-color: $primaryBlue;
                letter-spacing: 1px;
                font-size: 16px;
                width: 175px;
                text-align: center;
                border: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                float: right;
                padding: 15px;

                @media screen and (max-width: $smallBreakpoint) {

                    font-size: 14px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    width: 100%;
                }
            }

            .checkbox {
                margin-bottom: 20px;

                input[type=checkbox] {
                    -webkit-appearance: checkbox;
                    -moz-appearance: checkbox;
                    margin-right: 10px;
                }
            }
        }

        .close_contest {

            float: right;
            cursor: pointer;

            &:hover {

                text-decoration: underline;
            }
        }
    }
}