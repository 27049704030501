
.faq {

    padding: 0 0 50px 0;

    @media screen and (max-width: $mediumBreakpoint) {

        padding-bottom: 20px;
    }

    .faq-question {

        padding: 20px 50px;
        border-bottom: 1px solid $fifthLight;


        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;
        }

        .faq-question-box {

            max-width: 1050px;
            margin: 0 auto;

            &.open .faq-arrow {

                background-image: url('../images/faq-arrow-up.png');
            }

            .faq-question-box-inside {

                width: calc(100% - 80px);
                float: left;

                @media screen and (max-width: $mediumBreakpoint) {

                    width: calc(100% - 40px);
                }
            }

            .number {

                float: left;
                font-size: 22px;
                padding: 15px 0;
                width: 45px;
                text-align: center;
                background: $primaryFontColor;
                color: $primaryLight;
                margin-right: 30px;
                font-weight: 300;
                cursor: pointer;

                @media screen and (max-width: $mediumBreakpoint) {

                    font-size: 18px;
                    padding: 7px 0;
                    width: 30px;
                    margin-right: 20px;
                }
            }

            .question {

                font-size: 22px;
                line-height: 45px;
                text-transform: uppercase;
                font-weight: 300;
                cursor: pointer;

                @media screen and (max-width: $mediumBreakpoint) {

                    font-size: 18px;
                    line-height: 30px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    line-height: 20px;
                }
            }

            .answer {

                display: none;
                margin-bottom: 0;
                margin-top: 10px;
            }

            .dropdown {

                float: left;
                width: calc(100% - 75px);
            }
        }

        .faq-arrow {

            float: left;
            width: 80px;
            height: 45px;
            background: url('../images/faq-arrow-down.png') no-repeat right top;
            transition: background-image ease-out .2s;
            cursor: pointer;

            @media screen and (max-width: $mediumBreakpoint) {

                background-size: 30px;
                height: 30px;
                width: 40px;
            }
        }
    }

    .faq-title {

        margin: 50px auto;
        max-width: 1150px;
        padding: 0 50px;
        box-sizing: border-box;

        &:after {

            left: 50px;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            margin: 20px auto;
            padding: 0 20px;
            line-height: 34px;

            &:after {

                left: 20px;
            }
        }

        @media screen and (max-width: $smallBreakpoint) {

            font-size: 24px;
            margin-top: 0;
            padding-top: 20px;

            &:after {

                bottom: -13px;
            }
        }
    }

}