
.product-item {

    -js-display: flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 553px;


    @media screen and (min-width: $mediumBreakpoint) {

        flex-direction: row;
    }

    .product-image {

        //background: $primaryLight url('../images/ico_expand.svg') no-repeat top 10px right 10px;
        background: $primaryLight;
        background-size: 25px;
        position: relative;
        -js-display: flex;
        display: flex;
        flex-direction: column;
        height: 100vh;

        @media screen and (min-width: $mediumBreakpoint) {

            width: 50%;
            height: 100%;
        }

        .sketchfabTag {
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;

            .sketchfabTag-icon {
                display: none;
                background: #ffffff url("../images/img_3d.svg") no-repeat top left;
                min-height: 25px;
                background-size: auto 30px;
                padding-top: 35px;
                color: $fourthFontColor;
                font-size: 12px;
                text-align: right;

                &:hover {
                    color: $primaryBlue;
                }
            }
        }


        .product-background {

            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            cursor: pointer;
            flex-grow: 1;

            .tags {

                position: absolute;
                top: 10px;
                right: 10px;
                background: #ffffff url("../images/ico_expand.svg") no-repeat top right;
                min-height: 25px;
                padding-right: 45px;
                background-size: 25px;

                .new, .soon{

                    padding: 5px 10px;
                    border-radius: 3px;
                    background: $primaryBlue;
                    font-size: 12px;
                    color: $primaryLight;
                }

                .discontinued {

                    padding: 5px 10px;
                    border-radius: 3px;
                    background: $fourthdDark;
                    font-size: 12px;
                    margin-left: 10px;
                    color: $primaryLight;
                }
            }

            .text-save {

                position: absolute;
                top: 50px;
                right: 10px;
                color: $fourthFontColor;
                font-size: 12px;
                text-align: right;
            }
        }

        .product-colors {

            width: 100%;
            padding: 20px 50px 0;
            background: $primaryLight;

            &:after {

                content: '';
                display: table;
                clear: both;
            }

            @media screen and (max-width: $smallBreakpoint) {

                padding: 20px;
            }

            .color-title {

                float: left;
                margin-bottom: 10px;
                text-transform: uppercase;
                font-weight: 300;
                font-size: 18px;
                width: 100%;

                @media screen and (max-width: $smallBreakpoint) {

                    font-size: 14px;
                }
            }

            .colors-list {

                -js-display: flex;
                display: flex;
                clear: left;
                flex-direction: row;
                flex-wrap: wrap;
            }

            .product-color {

                width: 150px;
                margin: 0 10px 10px 0;
                text-align: center;
                cursor: pointer;
                padding-left: 30px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 20px auto;

                &.active {

                    cursor: auto;
                }

                img {

                    max-width: 37px;

                    @media screen and (max-width: $smallBreakpoint) {

                        width: 20px;
                    }
                }
            }

            .color-name {

                margin-top: 5px;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 14px;
                text-align: left;

                @media screen and (max-width: $smallBreakpoint) {

                    font-size: 10px;
                }
            }
        }
    }

    .product-infos {

        width: 50%;
        padding: 80px;
        background-color: $primaryFontColor;
        color: $primaryLight;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        @media screen and (min-width: $mediumBreakpoint) {

            width: 50%;
        }

        .title-underlined {

            margin-bottom: 60px;
            line-height: 35px;
        }

        .product-accordion {

            .product-accordion-item {

                margin-bottom: 25px;

                .product-accordion-title {

                    font-size: 20px;
                    line-height: 20px;
                    text-transform: uppercase;
                    font-weight: 300;
                    background: url('../images/product-title-line.png') repeat-x center center;
                    cursor: pointer;

                    div {

                        display: inline-block;
                        padding-right: 20px;
                        background-color: $primaryFontColor;
                    }

                    &:after {

                        content: '+';
                        width: 26px;
                        height: 26px;
                        background-color: $primaryFontColor;
                        border: 2px solid $primaryBlue;
                        border-radius: 28px;
                        font-size: 20px;
                        color: $primaryLight;
                        float: right;
                        margin-top: -5px;
                        text-align: center;
                        line-height: 28px;
                        transition: content ease-out .2s;
                    }
                }

                .product-accordion-value {

                    font-size: 18px;
                    color: $menuColor;
                    display: none;

                    .left {

                        float: left;
                        width: 49%;
                    }

                    .right {

                        float: right;
                        margin-top: 20px;
                        padding: 2px;
                        background-color: white;

                        .certified-logo {

                            height: 50px;
                        }
                    }


                    ul {

                        padding: 0;
                        list-style-type: none;
                        margin: 25px 0;

                        li {

                            margin-bottom: 15px;
                            padding-left: 45px;
                            background: url('../images/product-arrow-right.png') no-repeat left 20px center;

                            &.green {

                                color: #71E271;
                                position: relative;

                                .tip {

                                    display: none;
                                    position: absolute;
                                    background: $bodyColor;
                                    color: $primaryFontColor;
                                    border: 1px solid $fifthLight;
                                    padding: 20px;
                                    bottom: 40px;
                                    left: 0;

                                    p {

                                        margin: 0;
                                    }

                                    .green-logo {

                                        height: 40px;
                                        margin-bottom: 10px;
                                    }

                                    .square {

                                        position: absolute;
                                        width: 20px;
                                        height: 20px;
                                        background: $bodyColor;
                                        bottom: -10px;
                                        transform: rotate(45deg);
                                        border-bottom: 1px solid $fifthLight;
                                        border-right: 1px solid $fifthLight;
                                        left: 50px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.open .product-accordion-title:after {

                    content: '_';
                    line-height: 12px;
                }

                &:first-child .product-accordion-value {

                    display: block;
                }
            }
        }

        a {

            text-decoration: none;
            color: $primaryBlue;
        }
    }

    @media screen and (max-width: $largeBreakpoint) {

        .product-infos {

            padding: 50px;
        }

    }

    @media screen and (max-width: $mediumBreakpoint) {

        display: block;
        height: auto;

        .product-image {

            //display: block;
            width: 100%;
        }

        .product-infos {

            display: block;
            width: 100%;
        }
    }

    @media screen and (max-width: $smallBreakpoint) {

        display: block;

        .product-infos {

            padding: 20px;

            .title-underlined {

                font-size: 25px;
                line-height: 30px;
            }

            .product-accordion .product-accordion-item {

                .product-accordion-title {

                    font-size: 16px;
                }

                .product-accordion-value {

                    font-size: 14px;

                    ul > li {

                        padding-left: 30px;
                        background-position: left 10px center;
                    }
                }
            }
        }
    }
}

.share-row {
    -js-display: flex;
    display: flex;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 980px) {

        flex-direction: column;
    }

    .share-cell {
        flex-grow: 1;
        min-width: 25%;
        max-width: 50%;
        padding: 30px;
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
        color: $primaryFontColor;
        box-sizing: border-box;
        text-decoration: none;

        @media screen and (max-width: 980px) {

            width: 100%;
            max-width: 100%;
        }

        &:after {

            content: '';
            float: right;
            width: 32px;
            height: 32px;
            background-image: url('../images/share-sprite.png');
            background-repeat: no-repeat;
        }

        &:nth-child(2n+2) {

            background-color: #b6babd;
        }

        &.share {

            position: relative;

            &:after {

                background-position: top center;
            }
        }

        &.videos {
            color: $primaryFontColor;
            text-decoration: none;
            position: relative;

            &:after {

                height: 28px;
            }

            .videos-choice {

                display: none;
                min-width: 300px;
                cursor: default;
                position: absolute;
                padding: 20px;
                box-sizing: border-box;
                bottom: 110px;
                left: 20px;
                background: $bodyColor;
                border: 1px solid $fifthLight;

                @media screen and (max-width: $mediumBreakpoint) {

                    bottom: 95px;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    bottom: 165px;
                }

                .square {

                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: $bodyColor;
                    bottom: -10px;
                    transform: rotate(45deg);
                    border-bottom: 1px solid $fifthLight;
                    border-right: 1px solid $fifthLight;
                }

                ul {

                    margin: 0;
                    list-style-type: none;
                    padding-left: 0;

                    a {

                        color: $primaryFontColor;
                        text-decoration: none;
                        font-size: 16px;
                    }
                }

                .close {

                    float: right;
                    cursor: pointer;
                    margin-left: 40px;
                    font-size: 20px;
                    color: $primaryFontColor;
                }
            }

            &:after {

                background-position: center center;
            }
        }

        &.buy {

            &:after {

                background-position: bottom center;
            }
        }

        &.model3D {

            position: relative;

            &:after {

                position: absolute;
                right: 30px;
                top: calc(50% - 20px);
                transform: translateX(-50%);
                background-image: url('../images/3drender.svg');
                background-position: center center;
                background-size: contain;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;

                @media screen and (max-width: 1070px) {

                    right: 0;
                }
            }
        }

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;
        }

        @media screen and (max-width: $smallBreakpoint) {

            &.share, &.videos, &.buy {

                width: 100%;
                font-size: 16px;
            }
        }
    }

    .product-share {

        display: none;
        position: absolute;
        padding: 20px;
        box-sizing: border-box;
        bottom: 110px;
        left: 20px;
        background: $bodyColor;
        border: 1px solid $fifthLight;

        @media screen and (max-width: $mediumBreakpoint) {

            bottom: 95px;
        }

        @media screen and (max-width: $smallBreakpoint) {

            bottom: 165px;
        }

        .square {

            position: absolute;
            width: 20px;
            height: 20px;
            background: $bodyColor;
            bottom: -10px;
            transform: rotate(45deg);
            border-bottom: 1px solid $fifthLight;
            border-right: 1px solid $fifthLight;
        }

        .share-icon {

            font-size: 20px;
            margin-right: 20px;
            float: left;

            &.facebook {

                color: $facebookColor;
            }

            &.twitter {

                color: $twitterColor;
            }

            &.google {

                color: $googleColor;
            }

            &.linkedin {

                color: $linkedinColor;
            }

            &.pinterest {

                color: $pinterestColor;
            }
        }

        .close {

            float: right;
            cursor: pointer;
            margin-left: 40px;
            font-size: 20px;
            color: $primaryFontColor;
        }
    }
}

.partial-listing-product {

    .products-listing {

        .products-products {

            .filters {

                background-color: $primaryLight;

                .tag {

                    background-color: $fifthLight;
                }
            }
        }

        .product-block-row .product-block {

            &:nth-child(-n+4) {

                border-top: 1px solid $fifthLight;
            }

            @media screen and (max-width: $largeBreakpoint) {

                &:nth-child(n+4) {

                    border-top: none;
                }
            }

            @media screen and (max-width: $mediumBreakpoint) {

                &:nth-child(n+3){

                    border-top: none;
                }
            }
        }
    }

    @media screen and (max-width: $smallBreakpoint) {

        .products-menu {

            display: none;
        }
    }
}

.similar-products {

    .title {

        font-size: 28px;
        padding: 30px 50px;
        line-height: 34px;
        font-weight: 300;
        text-transform: uppercase;
        color: $primaryLight;
        background-color: $primaryFontColor;

        @media screen and (max-width: $smallBreakpoint) {

            padding: 20px;
        }
    }
}