
/*
 * 		Footer
 * 		------
 */

.footer {

	clear: both;
	background: $primaryDark url('../images/background_footer.jpg') no-repeat center center;
	background-size: cover;
	padding: 100px 0 0 0;

	@media screen and (max-width: $mediumBreakpoint) {

		padding: 0;
	}

	.footer-container {

		max-width: 1270px;
		margin: 0 auto;
	}

	.footer-wrapper {

		width: 100%;
		padding-bottom: 80px;

		@media screen and (max-width: $largeBreakpoint) {

			padding: 0 20px 80px;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			padding: 20px 20px 0;
		}

		.footer-block {

			float: left;
			width: 25%;
			vertical-align: top;

			@media screen and (max-width: $mediumBreakpoint) {
			
				width: 50%;
				margin-bottom: 50px;

				&:nth-child(odd) {

					clear: left;
				}

				&:nth-child(3) {
					margin-bottom: 20px;
				}
			}

			@media screen and (max-width: $smallBreakpoint) {

				width: 100%;
				margin-bottom: 20px;

				.content {

					display: none;
				}
			}

			.title-underlined {

				font-weight: 300;
				margin-bottom: 55px;

				.tcon {

					display: none;
				}

				@media screen and (max-width: $smallBreakpoint) {

					margin-bottom: 25px;

					&:after {

						bottom: -15px;
					}

					.tcon {

						display: block;
						float: right;
						top: -4px;
					}
				}
			}

			.title-3.light {

				margin: 20px 0;

				&.style {

					margin-top: 40px;
				}
			}

			ul {

				.list-item {

					padding: 0 0 20px 0;

					&.spaced {

						padding-left: 20px;
					}

					&:last-child {

						padding-bottom: 0;
					}
				}
			}
		}
	}

	.copyright-footer {

		display: block;
		width: 100%;
		padding: 20px 0;
		border-top: 1px solid $thirdDark;
		overflow: auto;
		color: $primaryLight;
		font-size: 14px;

		@media screen and (max-width: $largeBreakpoint) {

			padding: 20px;
		}

		@media screen and (max-width: 680px) {

			text-align: center;

			.left-block, .right-block {

				width: 100%;
			}
		}

		@media screen and (max-width: $tinyBreakpoint) {

			.link {

				width: 100%;
				display: block;
				margin: 0;
			}

			span {

				display: none;
			}
		}

		.left-block {

			float: left;

			.reading-text {

				margin: 0;
				color: $whiteFontColor;
			}
		}

		.right-block {
			
			float: right;
			color: $whiteFontColor;

			.link {

				padding: 0 5px;
			}
		}
	}
}



