
.carrieres-table {

    display: table;
    border-bottom: 2px solid $primaryLight;
    width: 100%;

    @media screen and (max-width: $mediumBreakpoint) {

        display: block;
    }

    .carrieres-page {

        display: table-cell;
        width: 70%;

        @media screen and (max-width: $mediumBreakpoint) {

            display: block;
            width: 100%;
        }

        .carrieres-content {

            margin: 0 auto;
            padding: 75px;

            .alert{

                margin-bottom: 20px;
            }

            &.carrieres-detail {

                max-width: 1000px;
                padding: 50px;

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 20px;
                }
            }

            @media screen and (max-width: $largeBreakpoint) {

                padding: 50px;
            }

            @media screen and (max-width: $mediumBreakpoint) {

                padding: 20px;
            }

            .title-underlined {

                font-weight: 400;

                @media screen and (max-width: $largeBreakpoint) {

                    margin: 0 0 50px;
                }

                @media screen and (max-width: $mediumBreakpoint) {

                    margin: 0 0 30px;
                }
            }

            .carrieres-content-inside {

                background: $primaryLight;
                padding: 50px;

                @media screen and (max-width: $mediumBreakpoint) {

                    padding: 20px;
                }

                p {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .carrieres-job {

                position: relative;
                float: left;
                width: calc(33.33334% - 20px);
                background-color: $primaryLight;
                padding: 30px;
                height: 250px;
                margin-right: 30px;
                box-sizing: border-box;
                margin-bottom: 30px;
                text-decoration: none;
                color: $primaryFontColor;

                @media screen and (max-width: $largeBreakpoint) {

                    width: calc(50% - 15px);

                    &:nth-child(2n+1) {

                        margin-right: 0;
                    }

                    &:nth-last-child(-n+3) {

                        margin-bottom: 0;
                    }
                }

                @media screen and (max-width: $smallBreakpoint) {

                    margin-bottom: 20px;
                    margin-right: 0;
                    width: 100%;
                    height: 200px;

                    &:nth-last-child(3) {

                        margin-bottom: 20px;
                    }
                }

                &:hover {

                    background: $fourthLight;
                }

                @media screen and (min-width: $largeBreakpoint) {

                    &:nth-child(3n+4) {

                        margin-right: 0;
                    }
                }

                &:after {

                    position: absolute;
                    content: '+';
                    right: 30px;
                    bottom: 20px;
                    width: 40px;
                    height: 40px;
                    line-height: 44px;
                    text-align: center;
                    border-radius: 40px;
                    border: 2px solid $primaryBlue;
                    color: $primaryBlue;
                    font-size: 30px;
                }

                .title {

                    font-size: 22px;
                    line-height: 24px;

                    @media screen and (max-width: $mediumBreakpoint) {

                        font-size: 18px;
                        line-height: 20px;
                    }
                }

                .details {

                    position: absolute;
                    left: 30px;
                    bottom: 30px;
                }
            }
        }
    }

    .carrieres-aside {

        display: table-cell;
        width: 30%;
        vertical-align: top;
        background: $primaryFontColor url('../images/home-swiper-top.png') no-repeat top right;
        padding: 50px;

        @media screen and (max-width: $largeBreakpoint) {

            padding: 50px 30px;
        }

        @media screen and (max-width: $mediumBreakpoint) {

            display: block;
            width: 100%;
            padding: 20px;
        }

        #carrieres-form {

            color: $primaryLight;

            .form-input, .form-file-label {

                margin-top: 5px;
            }

            .button {

                background: none;
                float: right;
                margin: 10px 0 0;
            }
        }
    }
}