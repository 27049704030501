
.about-dark-section {

    background-color: $primaryFontColor;
    margin: 0 auto;
    padding: 100px 50px;

    @media screen and (max-width: $largeBreakpoint) {

        padding: 50px;
    }

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 20px;
    }

    .reading-text {

        max-width: 850px;
        margin: 0 auto;
    }
}

.about-section {

    background-color: $primaryLight;
    padding: 100px 0;
    text-align: center;

    @media screen and (max-width: $largeBreakpoint) {

        padding: 50px;
    }

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 20px;
    }

    .about-timeline {

        position: relative;
        margin: 70px auto 0;
        padding: 30px 50px 100px;
        max-width: 1150px;
        background: url('../images/timeline-line.jpg') repeat-y center top;

        @media screen and (max-width: $largeBreakpoint) {

            padding: 50px 0;
        }

        @media screen and (max-width: $smallBreakpoint) {

            padding: 0 0 50px 30px;
            background-position: left center;
        }

        &:before {

            content: '';
            position: absolute;
            top: -7px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            width: 14px;
            height: 14px;
            background-color: $primaryFontColor;
            border-radius: 14px;

            @media screen and (max-width: $smallBreakpoint) {

                margin: 0;
                left: -3.5px;
                top: -1px;
            }
        }

        .about-timeline-item {

            position: relative;
            margin-bottom: 100px;
            text-align: right;

            @media screen and (max-width: $smallBreakpoint) {

                margin-bottom: 50px;
            }

            &:nth-child(n+2):before {

                content: '';
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 14px;
                height: 14px;
                background-color: $primaryFontColor;
                border-radius: 14px;

                @media screen and (max-width: $smallBreakpoint) {

                    right: inherit;
                    left: -33.5px;
                    top: 0;
                    bottom: inherit;
                }
            }

            &:last-child {

                margin-bottom: 0;
            }

            .text {

                position: absolute;
                width: 40%;
                text-align: left;
                top: 50%;
                transform: translateY(-50%);

                @media screen and (max-width: $smallBreakpoint) {

                    position: inherit;
                    transform: inherit;
                    top: inherit;
                    width: 100%;
                    float: left;
                }

                .title {

                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 500;
                    margin: 0 0 30px;

                    @media screen and (max-width: $smallBreakpoint) {

                        font-size: 16px;
                        line-height: 16px;
                        margin-bottom: 15px;
                    }
                }
            }

            .image {

                width: 40%;

                @media screen and (max-width: $smallBreakpoint) {

                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            &:nth-child(even) {

                text-align: left;

                .text {

                    right: 0;
                }
            }
        }
    }

    .button {

        margin: 0;
    }
}