

/*
 * 		Block publication detail
 * 		-------------
 */


.publication-detail-wrapper {

	max-width: 1000px;
	margin: 0 auto;

	@media screen and (min-width: $smallBreakpoint) {

		padding-top: 50px;
		
	}
	.publication-detail-image {

		width: 100%;
		display: block;
	}
	.publication-detail-content{

		background: $primaryLight;
		padding: 25px;

		@media screen and (min-width: $smallBreakpoint) {

			padding: 40px 100px;
			
		}
		.category-title {

			color: $fourthFontColor;
		}

		.title-underlined {

			margin-top: 15px;
			color: $primaryFontColor;
			line-height: 1.5em;
		}

		.reading-text {

			margin-bottom: 75px;
		}

		.social-sharing {

			.sharing-title {
				font-size: 0.938em;
				margin-bottom: 10px;
			}

			.link {

				display: inline-block;
				width: 14px;
				height: 14px;
				padding: 8px;
				margin: 0 5px 0 0;
				border: 3px solid $primaryBlue;
				border-radius: 50%;

				.icon {

					width: 100%;
				}

				&.facebook {

					border-color: $facebookColor;
				}
			}
		}
	}
}



/*
 * 		Block publication list
 * 		-------------
 */

.publication-wrapper {

	padding: 50px;
	overflow: auto;

	@media screen and (max-width: $mediumBreakpoint) {

		padding: 20px;
	}

	.publication-block {

		width: 25%;
		float: left;
		padding: 5px;

		@media screen and (max-width: $largeBreakpoint) {
			
			width: 33.33334%;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			width: 50%;
		}
		

		&.social {

			&.twitter, &.facebook {

				.content {

					position: relative;
					padding: 70px 35px 0;

					@media screen and (max-width: $smallBreakpoint) {

						padding: 50px 10px 0;
					}

					.author {
						
						padding: 0 0 25px 0;
						text-align: right;
						color: $secondaryFontColor;

						@media screen and (max-width: $tinyBreakpoint) {

							padding: 0 0 10px 0;
						}
					}
				}

				&:hover {

					color: $primaryFontColor;
				}
			}

			&.twitter .content {

				border-bottom: 5px solid $twitterColor;

				&:before {

					position: absolute;
					top: 18px;
					left: 19px;
					width: 25px;
					height: 25px;
					font: normal normal normal 25px/1 FontAwesome;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "\f099";
					color: $twitterColor;

					@media screen and (max-width: $smallBreakpoint) {

						top: 15px;
						left: 15px;
					}
				}
			}

			&.facebook .content {

				border-bottom: 5px solid $facebookColor;

				&:before {

					position: absolute;
					top: 18px;
					left: 19px;
					width: 25px;
					height: 25px;
					font: normal normal normal 25px/1 FontAwesome;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "\f09a";
					color: $facebookColor;

					@media screen and (max-width: $smallBreakpoint) {

						top: 15px;
						left: 15px;
					}
				}
			}

			&.instagram {

				.content {
					
					display: table;
					width: 100%;
					padding: 10px;

					.author, .author-image {

						display: table-cell;
					}

					.author-image {

						width: 18%;

						img {
							
							border: 1px solid transparent;
						    border-radius: 50%;
						    display: inline-block;
							width: 45px;
							height: 45px;

							@media screen and (max-width: $tinyBreakpoint) {

								width: 25px;
								height: 25px;
								padding: 10px 0;
							}
						}	
					}
					.author {

						position: relative;
						width: 82%;
						padding: 0 0 0 10px;
						vertical-align: middle;

						&:after {

							position: absolute;
							top: 12px;
							right: 10px;
							width: 25px;
							height: 25px;
							font: normal normal normal 25px/1 FontAwesome;
							text-rendering: auto;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							content: "\f16d";
							color: $instagramColor;

							@media screen and (max-width: $smallBreakpoint) {

								right: 0;
							}

							@media screen and (max-width: $tinyBreakpoint) {

								top: 70px;
							}
						}
					}
				}

				.image {

					width: 100%;
					display: block;
				}

			}
		}

		&.publication {

			.image {

				width: 100%;
				display: block;
			}

			.content {

				.link {

					color: $primaryBlue;
					text-transform: uppercase;
					padding-top: 30px;
				}	
			}

		}


		.link {

			display: inline-block;
			width: 100%;
			color: $primaryFontColor; 
			
			.content {

				background: $primaryLight;
				padding: 25px;
				overflow: hidden;

				@media screen and (max-width: $smallBreakpoint) {

					padding: 10px;
				}
			}

			&:hover {

				color: $primaryFontColor;

				.content {
					
					background-color: $secondaryLight;

				}
			}
		}
	}

	&.publication-item {

		max-width: 1110px;
		margin: 0 auto;

		.publication-block {

			padding: 0;
			width: 100%;
			background: $primaryLight;

			.content {

				padding: 50px 100px;

				@media screen and (max-width: $smallBreakpoint) {

					padding: 20px;
				}

				.category {

					margin-bottom: 20px;
					color: $fourthFontColor;
				}

				.title-underlined {

					font-size: 22px;
					font-weight: 400;
					line-height: 30px;
				}

				.publication-share {

					margin-top: 75px;

					@media screen and (max-width: $smallBreakpoint) {

						margin-top: 20px;
					}

					.publication-share-link {

						margin-bottom: 10px;
					}

					.share {

						font-size: 16px;
						width: 34px;
						line-height: 34px;
						text-align: center;
						border: 3px solid;
						display: block;
						float: left;
						margin-right: 10px;
						border-radius: 36px;
						transition: all ease-out .2s;

						@media screen and (max-width: $smallBreakpoint) {

							font-size: 14px;
							width: 28px;
							line-height: 28px;
						}

						&.share-facebook {

							color: $facebookColor;
							border-color: $facebookColor;

							&:hover {

								background-color: $facebookColor;
								color: $primaryLight;
							}
						}

						&.share-twitter {

							color: $twitterColor;
							border-color: $twitterColor;

							&:hover {

								background-color: $twitterColor;
								color: $primaryLight;
							}
						}

						&.share-google {

							color: $googleColor;
							border-color: $googleColor;

							&:hover {

								background-color: $googleColor;
								color: $primaryLight;
							}
						}

						&.share-linkedin {

							color: $linkedinColor;
							border-color: $linkedinColor;

							&:hover {

								background-color: $linkedinColor;
								color: $primaryLight;
							}
						}

						&.share-pinterest {

							color: $pinterestColor;
							border-color: $pinterestColor;

							&:hover {

								background-color: $pinterestColor;
								color: $primaryLight;
							}
						}
					}
				}
			}
		}
	}

	.next-publications {

		clear: both;
		width: 100%;
		padding-top: 40px;
		text-align: center;
		background: url('../images/loader.svg') no-repeat bottom 5px center;
		background-size: 70px;

		.button, .button:hover {

			background-color: $bodyColor;
			transition: opacity ease-out .2s;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			padding-top: 20px;
		}

		@media screen and (max-width: $smallBreakpoint) {

			background-size: 60px;
		}
	}

	&.loading .next-publications .button {

		opacity: 0;
	}

	.no-results {

		display: none;
		padding-top: 40px;
		margin-bottom: 40px;
	}
}