
/*
 * 		Header
 * 		------
 */

.header {

	.top-bar {

		display: table;
		width: 100%;
		clear: both;
		position: relative;

		@media screen and (max-width: $mediumBreakpoint) {

			display: none;
		}

		.left-block {

			display: table-cell;
			text-align: left;
			vertical-align: middle;

			.social-media {

				.link {

					float: left;
					width: 18px;
					height: 18px;
					padding: 16px;
					border-right: 1px solid $fifthLight;
					text-align: center;

					i.fa {

						font-size: 18px;
						line-height: 18px;
						color: $thirdFontColor;
					}

					&:hover {

						background: $fifthLight;
						transition: background 0.2s ease;
					}
				}
			}
		}

		.right-block {

			display: table-cell;
			text-align: right;

			nav {

				float: right;

				.link {

					float: left;
					display: inline-block;
					padding: 20px 15px;
					color: $thirdFontColor;
					border-left: 1px solid $fifthLight;
					font-size: 13px;
					font-family: $secondaryFont;
					line-height: 10px;
					text-transform: uppercase;

					@media screen and (max-width: 1280px) {

						font-size: 11px;
					}

					@media screen and (max-width: 1160px) {

						font-size: 9px;
					}

					&:first-child {

						border-left: none;
					}
				}

				a.link:hover {

					background: $fifthLight;
					transition: background 0.2s ease;
				}

				select.link {
					border: none;
					border-left: 1px solid $fifthLight;
					border-radius: 0;
					background: transparent url('../images/arrow-down-grey.svg') no-repeat right 10px center / auto 10px;
					line-height: 11px;
					padding: 20px 30px 19px 15px;
				}

			}
		}
		.logo-wrapper {

			position: absolute;
			left: 0;
			bottom: -138px;
			z-index: 5;
			width: 255px;
			height: 137px;
			border-right: 1px solid $fifthLight;
			border-bottom: 1px solid $fifthLight;
			text-align: center;
			background: $primaryLight url('../images/logo.svg') no-repeat center center;
			background-size: auto 78px;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

				background-image: url('../images/logo.png');
			}

			@media screen and (max-width: 1180px) {

				display: none;
			}
		}
	}
	.main-nav {

		position: relative;
		width: 100%;
		border-top: 1px solid $fifthLight;
		border-bottom: 1px solid $fifthLight;
		background: $primaryLight;
		clear: both;
		padding: 0 25px;

		@media screen and (max-width: $largeBreakpoint) {

			padding: 0 15px;
		}

		@media screen and (max-width: $mediumBreakpoint) {

			height: 46px;
			border-top: none;
		}

		&.fixed {

			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 4;
			transform: translate3d(0px, 0px, 0px);
			backface-visibility: hidden;
		}

		.left-block {

			float: left;
			position: relative;
			text-align: left;

			.logo-image {

				display: block;
				width: 110px;
				height: 71px;
				background: url('../images/logo.svg') no-repeat left center;
				background-size: auto 51px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

					background-image: url('../images/logo.png');
				}

				@media screen and (max-width: $mediumBreakpoint) {

					height: 45px;
					background-size: auto 35px;
				}

			}
		}

		.right-block {

			position: relative;
			float: right;

			@media screen and (max-width: $mediumBreakpoint) {

				display: none;
			}

			nav {

				float: right;

				.link {

					position: relative;
					float: left;
					padding: 30px 20px;
					font-size: 14px;
					line-height: 11px;
					text-transform: uppercase;
					color: $primaryFontColor;
					font-family: $secondaryFont;

					@media screen and (max-width: 1350px) {

						padding: 30px 10px;
					}

					&.active:after {

						position: absolute;
						height: 3px;
						background-color: $primaryRed;
						left: 20px;
						right: 20px;
						bottom: 22px;
						content: '';

						@media screen and (max-width: 1350px) {

							left: 10px;
							right: 10px;
						}
					}

					&.search {

						width: 20px;
						height: 20px;
						padding: 25px 0 25px;
						cursor: pointer;

						@media screen and (max-width: 1350px) {

							padding: 25px 0 25px 10px;
						}

						img {

							width: 100%;

							&.icon_close {

								display: none;
							}
						}
					}

					&:hover {

						color: $primaryBlue;

					}
				}
			}
			#search-input {

				position: absolute;
				right: 35px;
				top: 8px;
				width: 0;
				opacity: 0;
				transition: all ease-out .2s;

				&.open {

					width: 1015px;
					opacity: 1;

					@media screen and (max-width: 1350px) {

						width: 880px;
						right: 35px;
					}
				}

				input {

					float: left;
					width: calc(100% - 50px);
					padding: 10px 10px 5px 0;
					border: 0;
					font-family: $primaryFont;
					font-size: 25px;

					@-moz-document url-prefix() {

						width: calc(100% - 53px);
					}
				}

				button {

					float: left;
					border: none;
					font-size: 18px;
					padding: 5px 10px;
					line-height: 25px;
					background: $primaryBlue;
					color: $primaryLight;
					margin-top: 11px;
					cursor: pointer;
				}
			}
		}

		.mobile-block-menu {

			display: none;
			position: absolute;
			top: 0;
			right: 0;

			@media screen and (max-width: $mediumBreakpoint) {

				display: block;
			}

			.icon_search {

				height: 21px;
				float: left;
				padding: 12px 5px;
				margin-right: 50px;
			}

			.mobile-block {

				.tcon-menu--xbutterfly {

					float: left;
				}

				@media screen and (max-width: $mediumBreakpoint) {

					position: absolute;
					top: 0;
					right: 0;
					z-index: 12;
					background: $primaryLight;

					.tcon.tcon-menu--xbutterfly {

						height: 45px;
						padding: 5px 10px;
					}
				}
			}
		}
	}
}

.mobile-menu {

	position: fixed;
	z-index: 10;
	top: 0;
	right: -100%;
	height: 100%;
	width: 0;
	overflow: hidden;
	max-width: 420px;
	padding-bottom: 20px;
	transition: all .4s ease;
	background: $primaryFontColor;

	&.open {

		width: 100%;
		overflow-y: auto;
		right: 0;
	}

	.mobile-top {

		background: rgba(0,0,0,.2);

		.phone {

			float: left;
			color: $primaryLight;
			font-size: 16px;
			line-height: 45px;
			padding-left: 20px;
		}

		.languages {

			float: right;
			line-height: 45px;
			font-size: 16px;
			margin-right: 20px;

			a {

				color: white;
				text-decoration: none;
			}

			select.link {
				border: none;
				border-left: 1px solid $fifthLight;
				border-radius: 0;
				background: transparent url('../images/arrow-down-grey.svg') no-repeat right 10px center / auto 10px;
				line-height: 16px;
				padding: 14px 30px 15px 15px;
				text-transform: uppercase;
				font-size: 16px;
			}
		}

		.close_space {

			float: right;
			width: 45px;
			height: 45px;
		}
	}

	.main-links {

		padding: 40px 20px 20px;
		margin: 0;
		list-style-type: none;

		.main-link {

			display: block;
			color: $primaryLight;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 18px;
			margin-bottom: 20px;
			position: relative;
			line-height: 30px;
			font-weight: 300;

			span {

				padding-right: 10px;
				background: $primaryFontColor;
			}

			&:before {

				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 34px;
				height: 100%;
				background-color: $secondaryDark;
				background-image: url('../images/arrow-select-right-white.svg');
				background-size: 10px 10px;
				background-repeat: no-repeat;
				background-position: center center;
			}

			&:after {

				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				width: 100%;
				height: 1px;
				background: $secondaryDark;
			}
		}

		li:last-child .main-link {

			margin-bottom: 0;
		}
	}

	.small-links {

		padding: 20px;
		list-style-type: none;
		margin: 0;

		.small-link {

			color: $primaryLight;
			text-decoration: none;
			font-size: 14px;
			text-transform: uppercase;
			padding: 10px 0;
			display: block;
			border-top: 1px solid $secondaryDark;
			line-height: 18px;
			font-weight: 300;
		}

		li:last-child .small-link {

			border-bottom: 1px solid $secondaryDark;
		}
	}

	.social-media {

		padding: 0 20px;
		text-align: center;

		.link {

			margin: 0 10px;
			font-size: 20px;
		}
	}
}

.mobile-menu-overlay {
	display: none;
	position: fixed;
	z-index: 6;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

.form_search_mobile {

	display: none;
	padding: 10px;
	border-bottom: 1px solid $fifthLight;

	input {

		width: 100%;
	}
}

#IE-alert {

	display: none;
	padding: 10px 40px 10px 10px;
	background-color: $primaryRed;
	color: $whiteFontColor;
	font-weight: 700;
	font-size: 14px;
	position: relative;
	text-align: center;

	a {

		color: $secondaryFontColor;
	}

	.close {

		position: absolute;
		top: 10px;
		right: 10px;
		text-transform: uppercase;
		cursor: pointer;
	}
}
